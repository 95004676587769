<template>
  <div>
    <section class="product-list-heading" v-if="product">
      <div class="box-product-detail pt-4">
        <div class="container">
          <div class="breadcrumb">
            <router-link :to="`/product/${$route.params.slug}`">Back</router-link>
            <i class="icon-next"></i>
            <span>{{ product.name }}</span>
          </div>
          <b-row>
            <!-- Product Image -->
            <b-col md="4">
              <b-img-lazy fluid-grow :src="product.product_images[0].raw_image_url || product.product_images[0].full_image_url" :alt="setProductImgAltText(product.name)" @error="replaceByDefaultImage" />
            </b-col>
            <!-- Product Info -->
            <b-col md="2" class="mb-3 mb-lg-0">
              <h5 class="bold">{{ product.brand_name }}</h5>
              <h6 class="bold">{{ product.name }}</h6>
              <h6 class="bold">{{ product.bundle_dynamic_price ? $helpers.product.formatBundlePrice(product) : $helpers.format.price(product.price_display) }}</h6>
              <fawesome-pro
                v-for="i in 5" :key="i"
                :variant="product.rating.average - i > -1 ? 'fas' : 'far'"
                :icon="product.rating.average - i > -1 && product.rating.average - i < 0 ? 'star-half-alt' : 'star'"
                class="text-warning pr-1"
              />
              {{ (Math.round(product.rating.average * 10) / 10).toFixed(1) }}
              <div>{{ `${product.rating.count} Review${product.rating.count > 1 ? "s" : ""}` }}</div>
            </b-col>
            <b-col md="5" class="mb-3 mb-lg-0">
              <div v-for="ratingIndex in [5, 4, 3, 2, 1]" :key="ratingIndex" :class="{ 'mb-2': ratingIndex > 1 }">
                <div class="d-flex align-items-center">
                  <fawesome-pro
                    v-for="starIndex in 5" :key="starIndex"
                    :variant="starIndex <= ratingIndex ? 'fas' : 'far'"
                    icon="star"
                    class="text-warning pr-1"
                  />
                  <div class="ml-auto d-flex align-items-center">
                    <b>{{`${product.rating[`bintang_${ratingIndex}`]}`}}</b>
                    <fawesome-pro icon="user" class="ml-1" />
                  </div>
                </div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="{ '--width': (product.rating[`bintang_${ratingIndex}`] / product.rating.count) * 100 + '%' }"
                  ></div>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- Navbar Filter Rating -->
          <nav>
            <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
              <a
                class="nav-item nav-link"
                :class="navbarAll"
                data-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                @click="changeReviewNavbar('all')"
                >All</a
              >
              <a
                v-for="navbarIndex in [5, 4, 3, 2, 1]" :key="navbarIndex" 
                class="nav-item nav-link"
                :class="$data[`navbar${navbarIndex}star`]"
                data-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                @click="changeReviewNavbar(navbarIndex)"
              >
                {{ navbarIndex }} <fawesome-pro variant="fas" icon="star" class="text-warning" />
              </a>
            </div>
          </nav>
          <!-- Review Tab Content Start -->
          <div
            class="text-center my-5"
            v-if="productReviews.length == 0"
          >
            <img src="@/assets/img/empty-review.png" alt="" width="195" />
            <p>This product doesn't have review in this section</p>
          </div>
          <template v-else>
            <div
              class="row no-gutters p-3"
              :class="{ 'border-bottom': reviewIndex < productReviews.length - 1 }"
              v-for="(review, reviewIndex) of productReviews"
              :key="reviewIndex"
            >
              <div class="col-md-12 product-review">
                <div class="d-flex align-items-center">
                  <fawesome-pro
                    v-for="i in 5" :key="i"
                    :variant="review.rating - i > -1 ? 'fas' : 'far'"
                    icon="star"
                    class="text-warning pr-1"
                  />
                  <div class="review-date ml-1">{{ formatDate(review.created_at) }}</div>
                </div>
                <b class="d-inline-block mt-1">{{ review.reviewer_name }}</b>
                <div v-if="review.comment">{{ review.comment.trim() }}</div>
                <div v-if="review.tag">
                  <div
                    class="chip mt-2 mr-2"
                    v-for="(tag, index) of review.tag.split(';').filter(el => el)"
                    :key="index"
                  >
                    {{ tag }}
                  </div>
                </div>
                <div class="d-flex flex-wrap mt-2" v-if="review.review_images != null && review.review_images.length && review.review_images[0] != ''">
                  <b-img
                    :src="image"
                    alt=""
                    class="rounded cursor-pointer mr-2 mb-2 border-1px"
                    width="100"
                    height="100"
                    @click="reviewImage = review.review_images; reviewImageIndex = index"
                    v-b-modal.modal-image
                    v-for="(image, index) in review.review_images"
                    :key="index"
                  />
                </div>
              </div>
              <template v-if="review.review_feedbacks.length">
                <div class="col-md-6 mt-4 mb-4 merchant-feedback" v-for="(feedback, i) in review.review_feedbacks" :key="i">
                  <div class="merchant-profile">
                    <img src="@/assets/img/my-profile-default.png" alt="">
                  </div>
                  <div class="info ml-2">
                    <b class="text-gold">{{ product.seller_name }}</b>
                    <div class="time">{{ formatDate(feedback.created_at) }}</div>
                    <div>{{ feedback.comment }}</div>
                  </div>
                </div>
              </template>
            </div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              @change="pageChange"
            >
            </b-pagination>
          </template>
          <!-- Review Tab Content End -->
        </div>
      </div>
    </section>
    <ModalImage
      v-if="reviewImage"
      :items="reviewImage"
      :index="reviewImageIndex"
    ></ModalImage>
  </div>
</template>

<script>
//* Line code total from 400
import ModalImage from "@/components/modals/ModalImage.vue";

import { formatter } from "@/_helpers";

export default {
  name: "ProductDetailReview",
  components: {
    ModalImage,
  },
  data() {
    return {
      isLoading: false,

      product: null,
      productReviews: [],

      // Navbar class
      navbarAll: "active",
      navbar5star: "",
      navbar4star: "",
      navbar3star: "",
      navbar2star: "",
      navbar1star: "",

      // For Modal Image
      reviewImage: undefined,
      reviewImageIndex: undefined,

      // For Pagination
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
    };
  },
  computed: {
    productId() {
      return this.$route.params.slug.split("-").pop();
    }
  },
  mounted() {
    this.getProduct();
    this.getProductReview();
  },
  methods: {
    async getProduct() {
      try {
        const res = await this.$api.product.getProductDetail(this.productId);
        if (res.status === 200) this.product = res.data.data;
        else this.$helpers.toaster.make(res.data.message, "danger");
      } catch (error) {
        console.error(error);
      }
    },
    async getProductReview(rating) {
      try {
        this.isLoading = true;
        const res = await this.$api.product.getProductReviewList(
          this.productId,
          { 
            offset: this.currentPage - 1,
            limit: this.perPage,
            ...(rating ? { rating: rating } : undefined)
          }
        );
        if (res.status === 200) {
          this.productReviews = res.data.data;
          this.totalRows = res.data.meta.total;
        }
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },
    changeReviewNavbar(rating) {
      this.navbarAll = this.navbar5star = this.navbar4star = this.navbar3star = this.navbar2star = this.navbar1star = "";
      this.currentPage = 1; //* Reset current pagination on change review tab
      
      if (rating == "all") {
        this.navbarAll = "active";
        this.getProductReview();
      } else {
        this.$data[`navbar${rating}star`] = "active";
        this.getProductReview(rating);
      }
    },
    pageChange(page) {
      this.currentPage = page;
      this.getProductReview();
      window.scrollTo({
        top: 500,
        behavior: "smooth",
      });
    },
    replaceByDefaultImage() {
      this.product.product_images[0].raw_image_url  = this.product.product_images[0].full_image_url = require("@/assets/img/default-image.png");
    },
    formatDate(value) {
      return formatter.todayYesterday(value, "DD MMMM YYYY")
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`
    },
  },
};
</script>

<style scoped>
.progress-bar {
  background-color: #b28137;
  width: var(--width, auto);
}
.fa-user {
  color: #b28137;
}
div.review-item div p {
  color: #777777;
}
.review-date {
  color: #919191;
  font-size: 14px;
}
</style>